.brand-logo {
    @extend %logo;

    background-image: url($logo-path-01);

    &.color-brand {
        background-image: url($logo-path-brand);
    }

    &.color-p1 {
        background-image: url($logo-path-01);
    }

    &.color-p2 {
        background-image: url($logo-path-02);
    }

    &.color-p3 {
        background-image: url($logo-path-03);
    }

    &.color-p4 {
        background-image: url($logo-path-04);
    }

    &.color-p5 {
        background-image: url($logo-path-05);
    }

    &.color-p6 {
        background-image: url($logo-path-06);
    }
}
