// see http://iamvdo.me/en/blog/css-font-metrics-line-height-and-vertical-align
%icon {
  vertical-align: baseline;
  display: inline-block;
  font-family: AuiIcons;
  font-style: normal;
  font-size: inherit;
  font-weight:normal;
  speak:none;
  text-decoration:inherit;
  text-transform:none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
