@mixin button-colors($background, $border, $color) {
    background: $background;
    border-width: 1px;
    border-style: solid;
    border-color: $border;
    color: $color;
}

.button-primary-colors {
    @include button-colors(
        $color-button-primary-bg,
        $color-button-primary-bdr,
        $color-button-primary-fg
    );

    cursor: pointer;

    &:disabled {
        @include button-colors(
            $color-button-primary-disabled-bg,
            $color-button-primary-disabled-bdr,
            $color-button-primary-disabled-fg
        );
    }

    &:hover {
        @include button-colors(
            $color-button-primary-hover-bg,
            $color-button-primary-hover-bdr,
            $color-button-primary-hover-fg
        );

        &:active {
            @include button-colors(
                $color-button-primary-active-bg,
                $color-button-primary-active-bdr,
                $color-button-primary-active-fg
            );
        }
    }
}

.button-secondary-colors {
    @include button-colors(
        $color-button-secondary-bg,
        $color-button-secondary-bdr,
        $color-button-secondary-fg
    );

    cursor: pointer;

    &:disabled {
        @include button-colors(
            $color-button-secondary-disabled-bg,
            $color-button-secondary-disabled-bdr,
            $color-button-secondary-disabled-fg
        );
    }

    &:hover {
        @include button-colors(
            $color-button-secondary-hover-bg,
            $color-button-secondary-hover-bdr,
            $color-button-secondary-hover-fg
        );

        &:active {
            @include button-colors(
                $color-button-secondary-active-bg,
                $color-button-secondary-active-bdr,
                $color-button-secondary-active-fg
            );
        }
    }
}

.button-tertiary-colors {
    @include button-colors(
        $color-button-tertiary-bg,
        $color-button-tertiary-bdr,
        $color-button-tertiary-fg
    );

    cursor: pointer;

    &:disabled {
        @include button-colors(
            $color-button-tertiary-disabled-bg,
            $color-button-tertiary-disabled-bdr,
            $color-button-tertiary-disabled-fg
        );
    }

    &:hover {
        @include button-colors(
            $color-button-tertiary-hover-bg,
            $color-button-tertiary-hover-bdr,
            $color-button-tertiary-hover-fg
        );

        &:active {
            @include button-colors(
                $color-button-tertiary-active-bg,
                $color-button-tertiary-active-bdr,
                $color-button-tertiary-active-fg
            );
        }
    }
}

.button-plain-colors {
    @include button-colors(
        $color-button-plain-bg,
        $color-button-plain-bdr,
        $color-button-plain-fg
    );

    cursor: pointer;

    &:disabled {
        @include button-colors(
            $color-button-plain-disabled-bg,
            $color-button-plain-disabled-bdr,
            $color-button-plain-disabled-fg
        );
    }

    &:hover {
        @include button-colors(
            $color-button-plain-hover-bg,
            $color-button-plain-hover-bdr,
            $color-button-plain-hover-fg
        );

        &:active {
            @include button-colors(
                $color-button-plain-active-bg,
                $color-button-plain-active-bdr,
                $color-button-plain-active-fg
            );
        }
    }
}

.button-base {
    border-radius: $button-border-radius;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    text-transform: $button-text-transform;
    box-shadow: $button-shadow;
    padding: $button-vertical-padding $button-horizontal-padding;
    line-height: $button-line-height;
}

.button-base-container {
    border-radius: $button-border-radius;
    box-shadow: $button-shadow;
    padding: $button-vertical-padding $button-horizontal-padding;
}

.button-base-content {
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    text-transform: $button-text-transform;
    line-height: $button-line-height;
}

.border-radius-button { border-radius: $button-border-radius; }
.button-font-size { font-size: $button-font-size; }
.button-font-weight { font-weight: $button-font-weight; }
.button-text-transform { text-transform: $button-text-transform; }
.button-shadow { box-shadow: $button-shadow; }
.button-line-height { line-height: $button-line-height; }
.button-pad { padding: $button-vertical-padding $button-horizontal-padding; }

.button-pad-vertical {
    padding-top: $button-vertical-padding;
    padding-bottom: $button-vertical-padding;
}

.button-pad-horizontal {
    padding-left: $button-horizontal-padding;
    padding-right: $button-horizontal-padding;
}
