$radii: (
    0: $border-radius-0,
    1: $border-radius-1,
    2: $border-radius-2,
    3: $border-radius-3
);
$widths: (
    0: 0,
    1: $border-width-01,
    2: $border-width-02,
    3: $border-width-03
);
$sides: top, right, bottom, left;
$corners: top-right, bottom-right, bottom-left, top-left;
$styles: solid, dashed, dotted;

// All-in-one border rules
.border {
    border-width: $border-width-01;
    border-style: solid;
    border-color: $border-color-default;
}

// Per side border rules
@each $side in $sides {
    .border-#{$side} {
        border-#{$side}-width: $border-width-01;
        border-#{$side}-style: solid;
        border-color: $border-color-default;
    }
}

@each $width, $widthVar in $widths {
    .border-#{$width} {
        border-width: $widthVar;
        border-style: solid;
        border-color: $border-color-default;
    }

    @each $side in $sides {
        .border-#{$side}-#{$width} {
            border-#{$side}-width: $widthVar;
            border-#{$side}-style: solid;
            border-color: $border-color-default;
        }
    }
}

// Per style border rules
@each $style in $styles {
    .border-#{$style} {
        border-style: $style;
    }

    @each $side in $sides {
        .border-#{$style}-#{$side} {
            border-#{$side}-style: $style;
        }
    }
}

// Special border rules
.border-radius-circle { border-radius: $border-radius-circle; }
.border-radius-pill { border-radius: $border-radius-pill; }

@each $radius, $radiusVar in $radii {
    .border-radius-#{$radius} {
        border-radius: $radiusVar;
    }

    @each $corner in $corners {
        .border-radius-#{$corner}-#{$radius} {
            border-#{$corner}-radius: $radiusVar;
        }
    }

    @each $side in $sides {
        .border-radius-#{$side}-#{$radius} {
            @if $side == top {
                border-top-left-radius: $radiusVar;
                border-top-right-radius: $radiusVar;
            }

            @else if $side == right {
                border-top-right-radius: $radiusVar;
                border-bottom-right-radius: $radiusVar;
            }

            @else if $side == bottom {
                border-bottom-left-radius: $radiusVar;
                border-bottom-right-radius: $radiusVar;
            }

            @else {
                border-top-left-radius: $radiusVar;
                border-bottom-left-radius: $radiusVar;
            }
        }
    }
}

// Per color border rules
@import './border-colors';

// Box shadows
.shadow-0 { box-shadow: $shadow-depth0; }
.shadow-1 { box-shadow: $shadow-depth1; }
.shadow-2 { box-shadow: $shadow-depth2; }
