$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-heavy: 900;

@font-face {
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: $font-weight-thin;
    src: url('../../fonts/CiscoSansThin-webfont.eot');
    src:
        url('../../fonts/CiscoSansThin-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansThin-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansThin-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansThin-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansThin-webfont.svg#ciscosansthin') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: oblique;
    font-weight: $font-weight-thin;
    src: url('../../fonts/CiscoSansThinOblique-webfont.eot');
    src:
        url('../../fonts/CiscoSansThinOblique-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansThinOblique-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansThinOblique-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansThinOblique-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansThinOblique-webfont.svg#ciscosansthinoblique') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: $font-weight-extra-light;
    src: url('../../fonts/CiscoSansExtraLight-webfont.eot');
    src:
        url('../../fonts/CiscoSansExtraLight-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansExtraLight-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansExtraLight-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansExtraLight-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansExtraLight-webfont.svg#ciscosansextralight') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: oblique;
    font-weight: $font-weight-extra-light;
    src: url('../../fonts/CiscoSansExtraLightOblique-webfont.eot');
    src:
        url('../../fonts/CiscoSansExtraLightOblique-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansExtraLightOblique-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansExtraLightOblique-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansExtraLightOblique-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansExtraLightOblique-webfont.svg#ciscosansextralightoblique') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: $font-weight-light;
    src: url('../../fonts/CiscoSansLight-webfont.eot');
    src:
        url('../../fonts/CiscoSansLight-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansLight-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansLight-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansLight-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansLight-webfont.svg#ciscosanslight') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: oblique;
    font-weight: $font-weight-light;
    src: url('../../fonts/CiscoSansLightOblique-webfont.eot');
    src:
        url('../../fonts/CiscoSansLightOblique-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansLightOblique-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansLightOblique-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansLightOblique-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansLightOblique-webfont.svg#ciscosanslightoblique') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: $font-weight-normal;
    src: url('../../fonts/CiscoSansRegular-webfont.eot');
    src:
        url('../../fonts/CiscoSansRegular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansRegular-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansRegular-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansRegular-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansRegular-webfont.svg#ciscosansregular') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: oblique;
    font-weight: $font-weight-normal;
    src: url('../../fonts/CiscoSansRegularOblique-webfont.eot');
    src:
        url('../../fonts/CiscoSansRegularOblique-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansRegularOblique-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansRegularOblique-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansRegularOblique-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansRegularOblique-webfont.svg#ciscosansitalic') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: $font-weight-bold;
    src: url('../../fonts/CiscoSansBold-webfont.eot');
    src:
        url('../../fonts/CiscoSansBold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansBold-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansBold-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansBold-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansBold-webfont.svg#ciscosansbold') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: oblique;
    font-weight: $font-weight-bold;
    src: url('../../fonts/CiscoSansBoldOblique-webfont.eot');
    src:
        url('../../fonts/CiscoSansBoldOblique-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansBoldOblique-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansBoldOblique-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansBoldOblique-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansBoldOblique-webfont.svg#ciscosansbold_italic') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: normal;
    font-weight: $font-weight-heavy;
    src: url('../../fonts/CiscoSansHeavy-webfont.eot');
    src:
        url('../../fonts/CiscoSansHeavy-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansHeavy-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansHeavy-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansHeavy-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansHeavy-webfont.svg#ciscosansheavy') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: 'CiscoSans';
    font-style: oblique;
    font-weight: $font-weight-heavy;
    src: url('../../fonts/CiscoSansHeavyOblique-webfont.eot');
    src:
        url('../../fonts/CiscoSansHeavyOblique-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/CiscoSansHeavyOblique-webfont.woff2') format('woff2'),
        url('../../fonts/CiscoSansHeavyOblique-webfont.woff') format('woff'),
        url('../../fonts/CiscoSansHeavyOblique-webfont.ttf') format('truetype'),
        url('../../fonts/CiscoSansHeavyOblique-webfont.svg#ciscosansheavyoblique') format('svg');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

$font-family-primary: CiscoSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-secondary: CiscoSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-code: Consolas, monaco, monospace;
