@function getLightTextColor() {
    @if (lightness($color-primary-01) > 50) {
        @return $color-primary-01; // Lighter background, return dark color
    }

    @else {
        @return $color-primary-06; // Darker background, return light color
    }
}

@function getDarkTextColor() {
    @if (lightness($color-primary-01) > 50) {
        @return $color-primary-06; // Lighter background, return dark color
    }

    @else {
        @return $color-primary-01; // Darker background, return light color
    }
}

@function readabletextcolor($color) {
    @if (lightness($color) > 50) {
        @return getDarkTextColor(); // Lighter background, return dark color
    }

    @else {
        @return getLightTextColor(); // Darker background, return light color
    }
}

$colors: (
    p1: $color-primary-01,
    p2: $color-primary-02,
    p3: $color-primary-03,
    p4: $color-primary-04,
    p5: $color-primary-05,
    p6: $color-primary-06,
    neutral-70: $color-neutral-70,
    neutral-60: $color-neutral-60,
    neutral-30: $color-neutral-30,
    neutral-20: $color-neutral-20,
    neutral-15: $color-neutral-15,
    neutral-05: $color-neutral-05,
    neutral-00: $color-neutral-00,
    brand: $color-brand,
    brand-washed: $color-brand-washed,
    brand-burned: $color-brand-burned,
    s1: $color-secondary-09,
    s1-washed: $color-secondary-09-washed,
    s1-burned: $color-secondary-09-burned-1,
    s2: $color-secondary-03,
    s2-washed: $color-secondary-03-washed,
    s2-burned: $color-secondary-03-burned-1,
    s3: $color-secondary-01,
    s3-washed: $color-secondary-01-washed,
    s3-burned: $color-secondary-01-burned-1,
    s4: $color-secondary-04,
    s4-washed: $color-secondary-04-washed,
    s4-burned: $color-secondary-04-burned-1,
    s5: $color-secondary-02,
    s5-washed: $color-secondary-02-washed,
    s5-burned: $color-secondary-02-burned-1,
    category-1: $color-secondary-01,
    category-1-washed: $color-secondary-01-washed,
    category-1-burned-1: $color-secondary-01-burned-1,
    category-1-burned-2: $color-secondary-01-burned-2,
    category-2: $color-secondary-02,
    category-2-washed: $color-secondary-02-washed,
    category-2-burned-1: $color-secondary-02-burned-1,
    category-2-burned-2: $color-secondary-02-burned-2,
    category-3: $color-secondary-03,
    category-3-washed: $color-secondary-03-washed,
    category-3-burned-1: $color-secondary-03-burned-1,
    category-3-burned-2: $color-secondary-03-burned-2,
    category-4: $color-secondary-04,
    category-4-washed: $color-secondary-04-washed,
    category-4-burned-1: $color-secondary-04-burned-1,
    category-4-burned-2: $color-secondary-04-burned-2,
    category-5: $color-secondary-05,
    category-5-washed: $color-secondary-05-washed,
    category-5-burned-1: $color-secondary-05-burned-1,
    category-5-burned-2: $color-secondary-05-burned-2,
    category-6: $color-secondary-06,
    category-6-washed: $color-secondary-06-washed,
    category-6-burned-1: $color-secondary-06-burned-1,
    category-6-burned-2: $color-secondary-06-burned-2,
    category-7: $color-secondary-07,
    category-7-washed: $color-secondary-07-washed,
    category-7-burned-1: $color-secondary-07-burned-1,
    category-7-burned-2: $color-secondary-07-burned-2,
    category-8: $color-secondary-08,
    category-8-washed: $color-secondary-08-washed,
    category-8-burned-1: $color-secondary-08-burned-1,
    category-8-burned-2: $color-secondary-08-burned-2,
    category-9: $color-secondary-09,
    category-9-washed: $color-secondary-09-washed,
    category-9-burned-1: $color-secondary-09-burned-1,
    category-9-burned-2: $color-secondary-09-burned-2,
    info: $color-info,
    info-washed: $color-info-washed,
    info-burned: $color-info-burned,
    success: $color-success,
    success-washed: $color-success-washed,
    success-burned: $color-success-burned,
    warning: $color-warning,
    warning-washed: $color-warning-washed,
    warning-burned: $color-warning-burned,
    danger: $color-danger,
    danger-washed: $color-danger-washed,
    danger-burned: $color-danger-burned,
    highlight: $color-highlight,
    brand-highlight: $color-brand-highlight,
    danger-highlight: $color-danger-highlight
);

.color-inherit { color: inherit; }
.color-hover-inherit { &:hover { @extend .color-inherit; } }
.bg-inherit { background-color: inherit; }
.bg-hover-inherit { &:hover { @extend .bg-inherit; } }
.bg-transparent { background-color: transparent; }
.bg-hover-transparent { &:hover { @extend .bg-transparent; } }

@each $name, $color in $colors {
    .color-#{$name} { color: $color; }
    .color-#{$name}-contrast-text { color: readabletextcolor($color); }
    .color-hover-#{$name} { &:hover { @extend .color-#{$name}; } }
    .color-active-#{$name} { &:active { @extend .color-#{$name}; } }
    .color-focus-#{$name} { &:focus { @extend .color-#{$name}; } }

    .bg-#{$name} { background-color: $color; }
    .bg-hover-#{$name} { &:hover { @extend .bg-#{$name}; } }
    .bg-active-#{$name} { &:active { @extend .bg-#{$name}; } }
    .bg-focus-#{$name} { &:focus { @extend .bg-#{$name}; } }
}

.color-link {
    color: $color-brand;

    &:hover {
        color: $color-brand-washed;
    }

    &:active,
    &:visited {
        color: $color-brand-burned;
    }
}
