$font-size-01: 12px;
$font-size-02: 13px;
$font-size-03: 14px;
$font-size-04: 18px;
$font-size-05: 24px;
$font-size-06: 30px;
$font-size-07: 48px;
$font-size-08: 60px;
$font-size-09: 70px;

.text-footnote {
    font-family: $font-family-primary;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.25;
}

.text-body {
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4;
}

.text-link {
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
}

.text-sub-heading,
.text-label {
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 600;
}

.text-section-heading {
    font-family: $font-family-primary;
    font-size: 20px;
    font-weight: 200;
}

.text-modal-heading,
.text-empty-state-heading {
    font-family: $font-family-primary;
    font-size: 24px;
    font-weight: 300;
}
