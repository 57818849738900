$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-heavy: 900;

$font-family-primary:
    helvetica, 'helvetica neue',
    ubuntu,
    roboto, noto,
    'segoe ui', arial,
    sans-serif;
$font-family-secondary:
    helvetica, 'helvetica neue',
    ubuntu,
    roboto, noto,
    'segoe ui', arial,
    sans-serif;
$font-family-code: Consolas, monaco, monospace;
