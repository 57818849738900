/* theme colors */
$color-brand:           #44cfcb;
$color-brand-washed:    #bbedec;
$color-brand-burned:    #2c8482;

$color-primary-01: #071013;
$color-primary-02: #3d5258;
$color-primary-03: #a8b2b5;

$color-primary-04: #d3d8da;
$color-primary-05: #e9ebec;
$color-primary-06: white;

$color-secondary-01: #4a6698;
$color-secondary-01-washed:    #869bb1;
$color-secondary-01-burned:    #224870;

$color-secondary-02: #bbd9bf;
$color-secondary-02-washed:    #d9eadc;
$color-secondary-02-burned:    #899e8b;

$color-secondary-03: #931621;
$color-secondary-03-washed:    #cd959a;
$color-secondary-03-burned:    #6b1119;

$color-secondary-04: #de6b48;
$color-secondary-04-washed:    #f0bbab;
$color-secondary-04-burned:    #a24e35;

$color-secondary-05: #ffc857;
$color-secondary-05-washed:    #ffe6b2;
$color-secondary-05-burned:    #d1a448;

$color-success: $color-secondary-02;
$color-success-washed: lighten($color-success, 10%);
$color-success-burned:    darken($color-success, 15%);

$color-warning:        $color-secondary-05;
$color-warning-washed: $color-secondary-05-washed;
$color-warning-burned: $color-secondary-05-burned;

$color-danger:          $color-secondary-03;
$color-danger-washed:   $color-secondary-03-washed;
$color-danger-burned:   $color-secondary-03-burned;

// end of colors
$color-highlight:   #e6f6fc;
