
$spacing-0: 0;
$spacing-1: 8px;
$spacing-2: 12px;
$spacing-3: 16px;
$spacing-4: 24px;
$spacing-5: 32px;
$spacing-6: 40px;
$spacing-7: 48px;

$spacing-negative-1: $spacing-1 * -1;
$spacing-negative-2: $spacing-2 * -1;
$spacing-negative-3: $spacing-3 * -1;
$spacing-negative-4: $spacing-4 * -1;
$spacing-negative-5: $spacing-5 * -1;
$spacing-negative-6: $spacing-6 * -1;
$spacing-negative-7: $spacing-7 * -1;

$padding-default: $spacing-3;
$margin-default: $spacing-3;
$margin-negative-default: $spacing-negative-3;
