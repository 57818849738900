$font-size-01: 12px;
$font-size-02: 14px;
$font-size-03: 16px;
$font-size-04: 20px;
$font-size-05: 24px;
$font-size-06: 30px;
$font-size-07: 48px;
$font-size-08: 60px;
$font-size-09: 70px;

$line-height-01: 1;
$line-height-02: 1.2;
$line-height-03: 1.4; // paragraph
$line-height-04: 1.5;
$line-height-05: 1.7;

%font-base {
    font-style: normal;
    font-family: $font-family-primary;
    font-size: $font-size-02;
    line-height: $line-height-03;
}

$font-header-weight: 200;

%code {
    line-height: $line-height-03;
    font-family: $font-family-code;
}
