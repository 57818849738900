$logo-path-brand: './umbrella/svg/cisco-logo-brand.svg';
$logo-path-01: './umbrella/svg/cisco-logo-p1.svg';
$logo-path-02: './umbrella/svg/cisco-logo-p2.svg';
$logo-path-03: './umbrella/svg/cisco-logo-p3.svg';
$logo-path-04: './umbrella/svg/cisco-logo-p4.svg';
$logo-path-05: './umbrella/svg/cisco-logo-p5.svg';
$logo-path-06: './umbrella/svg/cisco-logo-p6.svg';

%logo {
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    font-weight: 100;
    height: 1.2em;
    padding-left: 3em;
    white-space: nowrap;

    &:empty {
        padding-left: 1.7em;
        display: inline-block;
    }
}
