$sides: top, right, bottom, left;

@each $name, $color in $colors {
    .border-color-#{$name} {
        border-color: $color;
    }

    .border-hover-color-#{$name} {
        &:hover { @extend .border-color-#{$name}; }
    }

    .border-active-color-#{$name} {
        &:active { @extend .border-color-#{$name}; }
    }

    .border-focus-color-#{$name} {
        &:focus { @extend .border-color-#{$name}; }
    }

    @each $side in $sides {
        .border-color-#{$side}-#{$name} {
            border-#{$side}-color: $color;
        }

        .border-hover-color-#{$side}-#{$name} {
            &:hover { @extend .border-color-#{$side}-#{$name}; }
        }

        .border-active-color-#{$side}-#{$name} {
            &:active { @extend .border-color-#{$side}-#{$name}; }
        }

        .border-focus-color-#{$side}-#{$name} {
            &:focus { @extend .border-color-#{$side}-#{$name}; }
        }
    }
}
