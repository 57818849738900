.pad { padding: $padding-default; }
.pad-0 { padding: $spacing-0; }
.pad-1 { padding: $spacing-1; }
.pad-2 { padding: $spacing-2; }
.pad-3 { padding: $spacing-3; }
.pad-4 { padding: $spacing-4; }
.pad-5 { padding: $spacing-5; }
.pad-6 { padding: $spacing-6; }
.pad-7 { padding: $spacing-7; }

.pad-left { padding-left: $padding-default; }
.pad-left-0 { padding-left: $spacing-0; }
.pad-left-1 { padding-left: $spacing-1; }
.pad-left-2 { padding-left: $spacing-2; }
.pad-left-3 { padding-left: $spacing-3; }
.pad-left-4 { padding-left: $spacing-4; }
.pad-left-5 { padding-left: $spacing-5; }
.pad-left-6 { padding-left: $spacing-6; }
.pad-left-7 { padding-left: $spacing-7; }

.pad-right { padding-right: $padding-default; }
.pad-right-0 { padding-right: $spacing-0; }
.pad-right-1 { padding-right: $spacing-1; }
.pad-right-2 { padding-right: $spacing-2; }
.pad-right-3 { padding-right: $spacing-3; }
.pad-right-4 { padding-right: $spacing-4; }
.pad-right-5 { padding-right: $spacing-5; }
.pad-right-6 { padding-right: $spacing-6; }
.pad-right-7 { padding-right: $spacing-7; }

.pad-bottom { padding-bottom: $padding-default; }
.pad-bottom-0 { padding-bottom: $spacing-0; }
.pad-bottom-1 { padding-bottom: $spacing-1; }
.pad-bottom-2 { padding-bottom: $spacing-2; }
.pad-bottom-3 { padding-bottom: $spacing-3; }
.pad-bottom-4 { padding-bottom: $spacing-4; }
.pad-bottom-5 { padding-bottom: $spacing-5; }
.pad-bottom-6 { padding-bottom: $spacing-6; }
.pad-bottom-7 { padding-bottom: $spacing-7; }

.pad-top { padding-top: $padding-default; }
.pad-top-0 { padding-top: $spacing-0; }
.pad-top-1 { padding-top: $spacing-1; }
.pad-top-2 { padding-top: $spacing-2; }
.pad-top-3 { padding-top: $spacing-3; }
.pad-top-4 { padding-top: $spacing-4; }
.pad-top-5 { padding-top: $spacing-5; }
.pad-top-6 { padding-top: $spacing-6; }
.pad-top-7 { padding-top: $spacing-7; }

.pad-vertical {
    padding-top: $padding-default;
    padding-bottom: $padding-default;
}

.pad-vertical-0 {
    padding-top: $spacing-0;
    padding-bottom: $spacing-0;
}

.pad-vertical-1 {
    padding-top: $spacing-1;
    padding-bottom: $spacing-1;
}

.pad-vertical-2 {
    padding-top: $spacing-2;
    padding-bottom: $spacing-2;
}

.pad-vertical-3 {
    padding-top: $spacing-3;
    padding-bottom: $spacing-3;
}

.pad-vertical-4 {
    padding-top: $spacing-4;
    padding-bottom: $spacing-4;
}

.pad-vertical-5 {
    padding-top: $spacing-5;
    padding-bottom: $spacing-5;
}

.pad-vertical-6 {
    padding-top: $spacing-6;
    padding-bottom: $spacing-6;
}

.pad-vertical-7 {
    padding-top: $spacing-7;
    padding-bottom: $spacing-7;
}

.pad-horizontal {
    padding-left: $padding-default;
    padding-right: $padding-default;
}

.pad-horizontal-0 {
    padding-left: $spacing-0;
    padding-right: $spacing-0;
}

.pad-horizontal-1 {
    padding-left: $spacing-1;
    padding-right: $spacing-1;
}

.pad-horizontal-2 {
    padding-left: $spacing-2;
    padding-right: $spacing-2;
}

.pad-horizontal-3 {
    padding-left: $spacing-3;
    padding-right: $spacing-3;
}

.pad-horizontal-4 {
    padding-left: $spacing-4;
    padding-right: $spacing-4;
}

.pad-horizontal-5 {
    padding-left: $spacing-5;
    padding-right: $spacing-5;
}

.pad-horizontal-6 {
    padding-left: $spacing-6;
    padding-right: $spacing-6;
}

.pad-horizontal-7 {
    padding-left: $spacing-7;
    padding-right: $spacing-7;
}
