// run regex against icon-char fun in _icon-font.scss
// find: .*== ([\w-]+).*\n.*\$char: "([\\A-Z0-9]+)";\n\s+}
// replace: $aui-var-$1: '$2';

$aui-font-path: './fonts' !default;
$aui-font-size-base:   14px !default;
$aui-line-height-base: 1 !default;

$aui-var-4-way-nav: '\E001';
$aui-var-ac-power: '\E002';
$aui-var-account: '\E003';
$aui-var-acessibility: '\E004';
$aui-var-active-speaker-cross: '\E005';
$aui-var-active-speaker: '\E006';
$aui-var-activities: '\E007';
$aui-var-add-contact: '\E008';
$aui-var-add-contain: '\E009';
$aui-var-add-outline: '\E00A';
$aui-var-add: '\E00B';
$aui-var-admin: '\E00C';
$aui-var-alarm: '\E00D';
$aui-var-alert: '\E00E';
$aui-var-alerting: '\E00F';
$aui-var-analysis: '\E010';
$aui-var-android-home: '\E011';
$aui-var-animation: '\E012';
$aui-var-annotation: '\E013';
$aui-var-applause: '\E014';
$aui-var-application: '\E015';
$aui-var-applications: '\E016';
$aui-var-arrow-left-tail: '\E017';
$aui-var-arrow-right-tail: '\E018';
$aui-var-asterisk: '\E019';
$aui-var-at-contain: '\E01A';
$aui-var-at: '\E01B';
$aui-var-attachment: '\E01C';
$aui-var-audio-broadcast: '\E01D';
$aui-var-audio-min: '\E01E';
$aui-var-audio-plus: '\E01F';
$aui-var-audio-settings: '\E020';
$aui-var-aux-camera: '\E021';
$aui-var-back: '\E022';
$aui-var-backup-data: '\E023';
$aui-var-battery: '\E024';
$aui-var-bell-cross: '\E025';
$aui-var-bell: '\E026';
$aui-var-blocked: '\E027';
$aui-var-bluetooth-contain-cross: '\E028';
$aui-var-bluetooth-contained: '\E029';
$aui-var-bluetooth-outline: '\E02A';
$aui-var-bluetooth: '\E02B';
$aui-var-bookmark: '\E02C';
$aui-var-briefcase: '\E02D';
$aui-var-brightness: '\E02E';
$aui-var-broadcast-message: '\E02F';
$aui-var-broken-image: '\E030';
$aui-var-browser: '\E031';
$aui-var-bug: '\E032';
$aui-var-calendar-meetings: '\E033';
$aui-var-calendar-weekly: '\E034';
$aui-var-calendar: '\E035';
$aui-var-call-forward-divert: '\E036';
$aui-var-call-handling: '\E037';
$aui-var-call-log: '\E038';
$aui-var-call-rate: '\E039';
$aui-var-callback: '\E03A';
$aui-var-camera: '\E03B';
$aui-var-certified: '\E03C';
$aui-var-chapters: '\E03D';
$aui-var-charging: '\E03E';
$aui-var-chats: '\E03F';
$aui-var-check-square-o: '\E040';
$aui-var-check-square: '\E041';
$aui-var-check: '\E042';
$aui-var-chevron-down: '\E043';
$aui-var-chevron-left: '\E044';
$aui-var-chevron-right-circle: '\E045';
$aui-var-chevron-right: '\E046';
$aui-var-chevron-up: '\E047';
$aui-var-circle: '\E048';
$aui-var-cisco: '\E049';
$aui-var-clock: '\E04A';
$aui-var-close-keyboard: '\E04B';
$aui-var-close: '\E04C';
$aui-var-cloud-upload: '\E04D';
$aui-var-cloud: '\E04E';
$aui-var-cog: '\E04F';
$aui-var-comment: '\E050';
$aui-var-communities: '\E051';
$aui-var-community: '\E052';
$aui-var-compass: '\E053';
$aui-var-computer: '\E054';
$aui-var-conference: '\E055';
$aui-var-contact-card: '\E056';
$aui-var-contact: '\E057';
$aui-var-create-page: '\E058';
$aui-var-data-usage: '\E059';
$aui-var-day: '\E05A';
$aui-var-dc-power: '\E05B';
$aui-var-default-app: '\E05C';
$aui-var-delete: '\E05D';
$aui-var-desk-phone: '\E05E';
$aui-var-devices: '\E05F';
$aui-var-diagnostics: '\E060';
$aui-var-dial: '\E061';
$aui-var-directory: '\E062';
$aui-var-disc-not-connected: '\E063';
$aui-var-disc: '\E064';
$aui-var-display: '\E065';
$aui-var-dms: '\E066';
$aui-var-document-camera-cross: '\E067';
$aui-var-document-camera: '\E068';
$aui-var-document: '\E069';
$aui-var-download-contain: '\E06A';
$aui-var-download: '\E06B';
$aui-var-draw: '\E06C';
$aui-var-dropdown: '\E06D';
$aui-var-edit-call: '\E06E';
$aui-var-edit: '\E06F';
$aui-var-email: '\E070';
$aui-var-emoticons: '\E071';
$aui-var-endpoint: '\E072';
$aui-var-eraser: '\E073';
$aui-var-error: '\E074';
$aui-var-ethernet: '\E075';
$aui-var-exclamation-circle: '\E076';
$aui-var-exclamation-triangle: '\E077';
$aui-var-exernal-calendar: '\E078';
$aui-var-exit-contain: '\E079';
$aui-var-exit-fullscreen: '\E07A';
$aui-var-exit-outline: '\E07B';
$aui-var-exit: '\E07C';
$aui-var-export: '\E07D';
$aui-var-extension-mobility: '\E07E';
$aui-var-eye: '\E07F';
$aui-var-fbw: '\E080';
$aui-var-feedback-clear: '\E081';
$aui-var-feedback-result: '\E082';
$aui-var-feedback: '\E083';
$aui-var-ffw: '\E084';
$aui-var-file-archive-o: '\E085';
$aui-var-file-audio-o: '\E086';
$aui-var-file-code-o: '\E087';
$aui-var-file-excel-o: '\E088';
$aui-var-file-image-o: '\E089';
$aui-var-file-o: '\E08A';
$aui-var-file-pdf-o: '\E08B';
$aui-var-file-powerpoint-o: '\E08C';
$aui-var-file-text-o: '\E08D';
$aui-var-file-text: '\E08E';
$aui-var-file-video-o: '\E08F';
$aui-var-file-word-o: '\E090';
$aui-var-file: '\E091';
$aui-var-filter: '\E092';
$aui-var-flagged: '\E093';
$aui-var-folder: '\E094';
$aui-var-forced-sign-in: '\E095';
$aui-var-forward-to-mobility: '\E096';
$aui-var-fullscreen: '\E097';
$aui-var-general-source-cross: '\E098';
$aui-var-general-source: '\E099';
$aui-var-google-analytics: '\E09A';
$aui-var-gpu-graphicscard: '\E09B';
$aui-var-graph: '\E09C';
$aui-var-grid-view: '\E09D';
$aui-var-ground: '\E09E';
$aui-var-group-call: '\E09F';
$aui-var-group-chat: '\E0A0';
$aui-var-headset-cross: '\E0A1';
$aui-var-headset: '\E0A2';
$aui-var-help-alt: '\E0A3';
$aui-var-help-outline: '\E0A4';
$aui-var-help: '\E0A5';
$aui-var-highlight-line: '\E0A6';
$aui-var-highlight: '\E0A7';
$aui-var-highlighter-check: '\E0A8';
$aui-var-highlighter: '\E0A9';
$aui-var-home: '\E0AA';
$aui-var-hue: '\E0AB';
$aui-var-hunt-group: '\E0AC';
$aui-var-idefix: '\E0AD';
$aui-var-image-contain: '\E0AE';
$aui-var-image: '\E0AF';
$aui-var-import: '\E0B0';
$aui-var-inbox: '\E0B1';
$aui-var-incoming-call: '\E0B2';
$aui-var-info-circle: '\E0B3';
$aui-var-info-outline: '\E0B4';
$aui-var-info: '\E0B5';
$aui-var-instant-meeting: '\E0B6';
$aui-var-intercom-duplex-connected: '\E0B7';
$aui-var-intercom-whisper: '\E0B8';
$aui-var-intercom: '\E0B9';
$aui-var-invite: '\E0BA';
$aui-var-jump-out: '\E0BB';
$aui-var-key-expansion-module: '\E0BC';
$aui-var-keyboard-close: '\E0BD';
$aui-var-keyboard: '\E0BE';
$aui-var-keywords: '\E0BF';
$aui-var-language: '\E0C0';
$aui-var-laser-pointer: '\E0C1';
$aui-var-layers: '\E0C2';
$aui-var-layout: '\E0C3';
$aui-var-leave-meeting: '\E0C4';
$aui-var-left-arrow: '\E0C5';
$aui-var-lightbulb: '\E0C6';
$aui-var-like: '\E0C7';
$aui-var-line-out-left: '\E0C8';
$aui-var-line-out-right: '\E0C9';
$aui-var-link: '\E0CA';
$aui-var-list-menu: '\E0CB';
$aui-var-list-view: '\E0CC';
$aui-var-location: '\E0CD';
$aui-var-lock-contain: '\E0CE';
$aui-var-lock: '\E0CF';
$aui-var-locked-speaker: '\E0D0';
$aui-var-mail-read: '\E0D1';
$aui-var-mail: '\E0D2';
$aui-var-manage-cable: '\E0D3';
$aui-var-maximize: '\E0D4';
$aui-var-media-viewer: '\E0D5';
$aui-var-meet-me: '\E0D6';
$aui-var-meeting-room: '\E0D7';
$aui-var-merge-call: '\E0D8';
$aui-var-message: '\E0D9';
$aui-var-mic-in: '\E0DA';
$aui-var-micro-blog: '\E0DB';
$aui-var-microphone: '\E0DC';
$aui-var-minimize: '\E0DD';
$aui-var-missed-call: '\E0DE';
$aui-var-mobile-phone: '\E0DF';
$aui-var-mobile-presenter: '\E0E0';
$aui-var-month: '\E0E1';
$aui-var-more: '\E0E2';
$aui-var-move-page: '\E0E3';
$aui-var-multi-display: '\E0E4';
$aui-var-music: '\E0E5';
$aui-var-mute: '\E0E6';
$aui-var-no-signal: '\E0E7';
$aui-var-notebook-in: '\E0E8';
$aui-var-notes: '\E0E9';
$aui-var-numbered-input: '\E0EA';
$aui-var-numbered-output: '\E0EB';
$aui-var-off-hook: '\E0EC';
$aui-var-other-phone: '\E0ED';
$aui-var-outbox: '\E0EE';
$aui-var-outgoing-call: '\E0EF';
$aui-var-paired-audio: '\E0F0';
$aui-var-paired-call: '\E0F1';
$aui-var-panel-shift-left: '\E0F2';
$aui-var-panel-shift-right: '\E0F3';
$aui-var-parked: '\E0F4';
$aui-var-participant-list: '\E0F5';
$aui-var-pass-mouse: '\E0F6';
$aui-var-pause: '\E0F7';
$aui-var-pc: '\E0F8';
$aui-var-pencil: '\E0F9';
$aui-var-persistent-chat: '\E0FA';
$aui-var-phone-cross: '\E0FB';
$aui-var-phone: '\E0FC';
$aui-var-picture-in-picture: '\E0FD';
$aui-var-pin: '\E0FE';
$aui-var-play-contained: '\E0FF';
$aui-var-play: '\E100';
$aui-var-playlist: '\E101';
$aui-var-plugin: '\E102';
$aui-var-plus: '\E103';
$aui-var-point: '\E104';
$aui-var-poll: '\E105';
$aui-var-popout: '\E106';
$aui-var-popup-dialogue: '\E107';
$aui-var-power-contain: '\E108';
$aui-var-power: '\E109';
$aui-var-presentation: '\E10A';
$aui-var-prevent-download-contain: '\E10B';
$aui-var-prevent-download: '\E10C';
$aui-var-print: '\E10D';
$aui-var-priority: '\E10E';
$aui-var-privacy: '\E10F';
$aui-var-private: '\E110';
$aui-var-profile-settings: '\E111';
$aui-var-proximity-not-connected: '\E112';
$aui-var-proximity: '\E113';
$aui-var-quality: '\E114';
$aui-var-question-circle: '\E115';
$aui-var-raise-hand: '\E116';
$aui-var-read-email: '\E117';
$aui-var-recent-apps: '\E118';
$aui-var-record: '\E119';
$aui-var-redial: '\E11A';
$aui-var-refresh: '\E11B';
$aui-var-remove-contact: '\E11C';
$aui-var-remove-contain: '\E11D';
$aui-var-remove-outline: '\E11E';
$aui-var-remove: '\E11F';
$aui-var-reply-all: '\E120';
$aui-var-report: '\E121';
$aui-var-reset: '\E122';
$aui-var-right-arrow-closed-contained: '\E123';
$aui-var-right-arrow-closed-outline: '\E124';
$aui-var-right-arrow-contain: '\E125';
$aui-var-right-arrow-contained: '\E126';
$aui-var-right-arrow-outline: '\E127';
$aui-var-right-arrow: '\E128';
$aui-var-ringer-settings: '\E129';
$aui-var-rotate-object-ccw: '\E12A';
$aui-var-rotate-object-cw: '\E12B';
$aui-var-rtprx-rtptx-duplex: '\E12C';
$aui-var-rtprx: '\E12D';
$aui-var-rtptx: '\E12E';
$aui-var-running-application: '\E12F';
$aui-var-save: '\E130';
$aui-var-schedule-add: '\E131';
$aui-var-screen-capture-square: '\E132';
$aui-var-screen-capture: '\E133';
$aui-var-sd: '\E134';
$aui-var-search: '\E135';
$aui-var-self-view-alt: '\E136';
$aui-var-self-view-crossed: '\E137';
$aui-var-self-view: '\E138';
$aui-var-send-email: '\E139';
$aui-var-send: '\E13A';
$aui-var-sent: '\E13B';
$aui-var-setup-assistant: '\E13C';
$aui-var-share-contain: '\E13D';
$aui-var-share-content: '\E13E';
$aui-var-share: '\E13F';
$aui-var-sign-in: '\E140';
$aui-var-sign-out: '\E141';
$aui-var-signal-1: '\E142';
$aui-var-signal-2: '\E143';
$aui-var-signal-3: '\E144';
$aui-var-signal-4: '\E145';
$aui-var-skip-bw: '\E146';
$aui-var-skip-fw: '\E147';
$aui-var-slides: '\E148';
$aui-var-soft-phone: '\E149';
$aui-var-software: '\E14A';
$aui-var-sort-amount-asc: '\E14B';
$aui-var-sort-amount-desc: '\E14C';
$aui-var-sound: '\E14D';
$aui-var-space: '\E14E';
$aui-var-spam: '\E14F';
$aui-var-spark: '\E150';
$aui-var-speaker-cross: '\E151';
$aui-var-speaker-out-left: '\E152';
$aui-var-speaker: '\E153';
$aui-var-speed-dial: '\E154';
$aui-var-spinner: '\E155';
$aui-var-square-o: '\E156';
$aui-var-star: '\E157';
$aui-var-step-backward: '\E158';
$aui-var-step-forward: '\E159';
$aui-var-step-next: '\E15A';
$aui-var-step-prev: '\E15B';
$aui-var-stop: '\E15C';
$aui-var-storage: '\E15D';
$aui-var-subscribe: '\E15E';
$aui-var-swap-calls: '\E15F';
$aui-var-swap-camera: '\E160';
$aui-var-swap-video-camera: '\E161';
$aui-var-tables: '\E162';
$aui-var-tablet: '\E163';
$aui-var-tags: '\E164';
$aui-var-team-collapsed-view: '\E165';
$aui-var-team-expanded-view: '\E166';
$aui-var-terminalalt: '\E167';
$aui-var-text-color: '\E168';
$aui-var-text-format: '\E169';
$aui-var-text-size: '\E16A';
$aui-var-text: '\E16B';
$aui-var-thumbnail-view: '\E16C';
$aui-var-time: '\E16D';
$aui-var-timeline: '\E16E';
$aui-var-too-fast: '\E16F';
$aui-var-too-slow: '\E170';
$aui-var-tools: '\E171';
$aui-var-touch-gesture: '\E172';
$aui-var-touch-point: '\E173';
$aui-var-touch: '\E174';
$aui-var-transcript: '\E175';
$aui-var-transfer-to-mobile: '\E176';
$aui-var-trash: '\E177';
$aui-var-universal-inbox: '\E178';
$aui-var-unlock: '\E179';
$aui-var-upload-contain: '\E17A';
$aui-var-upload: '\E17B';
$aui-var-usb: '\E17C';
$aui-var-user: '\E17D';
$aui-var-vibrate: '\E17E';
$aui-var-video-cross: '\E17F';
$aui-var-video-input: '\E180';
$aui-var-video-layout: '\E181';
$aui-var-video-settings: '\E182';
$aui-var-video-tips: '\E183';
$aui-var-video: '\E184';
$aui-var-view-feed-dual: '\E185';
$aui-var-view-feed-multi: '\E186';
$aui-var-view-feed-single: '\E187';
$aui-var-view-preview-telepresence: '\E188';
$aui-var-view-side-by-side: '\E189';
$aui-var-view-stacked: '\E18A';
$aui-var-voicemail: '\E18B';
$aui-var-volume-cross: '\E18C';
$aui-var-volume: '\E18D';
$aui-var-waiting-silence: '\E18E';
$aui-var-wallpaper: '\E18F';
$aui-var-warning: '\E190';
$aui-var-watchlist: '\E191';
$aui-var-web-camera: '\E192';
$aui-var-web-sharing: '\E193';
$aui-var-webex: '\E194';
$aui-var-webhook: '\E195';
$aui-var-week: '\E196';
$aui-var-whiteboard-cross: '\E197';
$aui-var-whiteboard: '\E198';
$aui-var-wifi: '\E199';
$aui-var-work: '\E19A';
$aui-var-zip: '\E19B';
$aui-var-zoom-in: '\E19C';
$aui-var-zoom-out: '\E19D';
