%subheading {
    color: $color-primary-02;
    font-size: 0.5em;
}

%header {
    font-family: $font-family-primary;
    font-weight: $font-header-weight;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        display: inline;
        font-size: inherit;
    }

    small {
        padding-left: 0.6em;
        color: $color-primary-03;
    }

    .subheading {
        @extend %subheading;
    }

    sub {
        @extend %subheading;

        bottom: inherit;
        display: block;
        line-height: inherit;
    }
}

.header {
    @extend %header;
}
