$color-button-primary-fg: $color-primary-06;
$color-button-primary-bg: $color-brand;
$color-button-primary-bdr: $color-brand;
$color-button-primary-hover-fg: $color-primary-06;
$color-button-primary-hover-bg: $color-brand-burned;
$color-button-primary-hover-bdr: $color-brand-burned;
$color-button-primary-active-fg: $color-primary-06;
$color-button-primary-active-bg: darken($color-brand-burned, 5%);
$color-button-primary-active-bdr: darken($color-brand-burned, 5%);
$color-button-primary-disabled-fg: $color-primary-06;
$color-button-primary-disabled-bg: $color-brand-washed;
$color-button-primary-disabled-bdr: $color-brand-washed;

$color-button-secondary-fg: $color-brand;
$color-button-secondary-bg: $color-primary-06;
$color-button-secondary-bdr: $color-brand;
$color-button-secondary-hover-fg: $color-brand-burned;
$color-button-secondary-hover-bg: $color-primary-06;
$color-button-secondary-hover-bdr: $color-brand-burned;
$color-button-secondary-active-fg: darken($color-brand-burned, 5%);
$color-button-secondary-active-bg: $color-primary-06;
$color-button-secondary-active-bdr: darken($color-brand-burned, 5%);
$color-button-secondary-disabled-fg: $color-brand-washed;
$color-button-secondary-disabled-bg: $color-primary-06;
$color-button-secondary-disabled-bdr: $color-brand-washed;

$color-button-tertiary-fg: $color-brand;
$color-button-tertiary-bg: transparent;
$color-button-tertiary-bdr: transparent;
$color-button-tertiary-hover-fg: $color-brand-burned;
$color-button-tertiary-hover-bg: $color-primary-04;
$color-button-tertiary-hover-bdr: $color-primary-04;
$color-button-tertiary-active-fg: darken($color-brand-burned, 5%);
$color-button-tertiary-active-bg: $color-primary-04;
$color-button-tertiary-active-bdr: $color-primary-04;
$color-button-tertiary-disabled-fg: $color-brand-washed;
$color-button-tertiary-disabled-bg: transparent;
$color-button-tertiary-disabled-bdr: transparent;

$color-button-plain-fg: $color-brand;
$color-button-plain-bg: transparent;
$color-button-plain-bdr: transparent;
$color-button-plain-hover-fg: $color-brand-burned;
$color-button-plain-hover-bg: transparent;
$color-button-plain-hover-bdr: transparent;
$color-button-plain-active-fg: darken($color-brand-burned, 5%);
$color-button-plain-active-bg: transparent;
$color-button-plain-active-bdr: transparent;
$color-button-plain-disabled-fg: $color-brand-washed;
$color-button-plain-disabled-bg: transparent;
$color-button-plain-disabled-bdr: transparent;

$button-border-radius: $border-radius-1;
$button-vertical-padding: 0;
$button-line-height: $spacing-5;
$button-horizontal-padding: $spacing-3;
$button-shadow: $shadow-depth0;
$button-font-size: $font-size-01;
$button-font-weight: $font-weight-bold;
$button-text-transform: uppercase;
