$logo-path-brand: './hastings/svg/core-css-logo-brand.svg';
$logo-path-01: './hastings/svg/core-css-logo-p1.svg';
$logo-path-02: './hastings/svg/core-css-logo-p2.svg';
$logo-path-03: './hastings/svg/core-css-logo-p3.svg';
$logo-path-04: './hastings/svg/core-css-logo-p4.svg';
$logo-path-05: './hastings/svg/core-css-logo-p5.svg';
$logo-path-06: './hastings/svg/core-css-logo-p6.svg';

%logo {
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-weight: 100;
    height: 2em;
    padding-left: 1.7em;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &:empty {
        padding-left: 1.4em;
        display: inline-block;
    }
}
