%menu-item {
    &.color-main-menu-active {
        color: $color-main-menu-active;
    }
}

.color-main-menu-1 {
    @extend %menu-item;

    color: $color-main-menu-01;
}

.color-main-menu-2 {
    @extend %menu-item;

    color: $color-main-menu-02;
}

.color-main-menu-3 {
    @extend %menu-item;

    color: $color-main-menu-03;
}

.bg-main-menu {
    background-color: $color-main-menu-bg;
}

.font-main-menu {
    font-family: $font-family-primary;
    font-weight: $font-weight-thin;
}

.color-main-menu-active {
    color: $color-main-menu-active;
}
