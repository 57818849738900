// $iconList: listtojs(
//     'icons',
//     (
//         'accounts',
//         'home',
//         'up-level',
//         'network',
//         'laptop',
//         'desktop',
//         'mobile',
//         'server',
//         'database',
//         'site',
//         'unknown',
//         'integration',
//         'secure',
//         'insecure',
//         'visualization',
//         'health',
//         'search',
//         'close',
//         'cloud',
//         'cloud-upload',
//         'security',
//         'edit',
//         'share',
//         'caret-down',
//         'support',
//         'help',
//         'chevron-left',
//         'chevron-right',
//         'chevron-down',
//         'chevron-up',
//         'plus',
//         'user',
//         'users',
//         'delete',
//         'expand',
//         'collapse',
//         'configure',
//         'blocked',
//         'allowed',
//         'move',
//         'bookmark',
//         'date-range',
//         'checked',
//         'unchecked',
//         'flag',
//         'tags',
//         'alert-circle',
//         'alert',
//         'schedule',
//         'trending-up',
//         'trending-down',
//         'proxy',
//         'next',
//         'previous',
//         'download',
//         'link',
//         'zoom-in',
//         'antivirus',
//         'amp',
//         'dashboard',
//         'identities',
//         'policies',
//         'reporting',
//         'bug',
//         'file-archive',
//         'file-audio',
//         'file-code',
//         'file-excel',
//         'file-image',
//         'file-pdf',
//         'file-powerpoint',
//         'file-text-o',
//         'file-text',
//         'file-video',
//         'file-word',
//         'file-o',
//         'file',
//         'folder',
//         'info',
//         'more',
//         'read-only',
//         'external',
//         'trash',
//     )
// );

@each $name, $content in $iconContent {
    .i-#{$name} {
        @extend %icon;

        &::before {
            content: $content;
        }

        > * {
            font-family: $font-family-primary;
        }
    }
}

.i-caret-up {
    @extend %icon;

    transform: rotate(180deg);

    &::before {
        content: '\E06D';
    }
}
