.font-family-primary {
    font-family: $font-family-primary;
}

.font-family-secondary {
    font-family: $font-family-secondary;
}

.font-family-inherit { font-family: inherit; }

.font-size-1 { font-size: $font-size-01; }
.font-size-2 { font-size: $font-size-02; }
.font-size-3 { font-size: $font-size-03; }
.font-size-4 { font-size: $font-size-04; }
.font-size-5 { font-size: $font-size-05; }
.font-size-6 { font-size: $font-size-06; }
.font-size-7 { font-size: $font-size-07; }
.font-size-8 { font-size: $font-size-08; }
.font-size-9 { font-size: $font-size-09; }

.font-size-inherit { font-size: inherit; }

.thin { font-weight: $font-weight-thin; }
.extra-light { font-weight: $font-weight-extra-light; }
.light { font-weight: $font-weight-light; }
.regular { font-weight: $font-weight-normal; }
.bold { font-weight: $font-weight-bold; }
.heavy { font-weight: $font-weight-heavy; }
