%box {
    box-sizing: border-box;
    flex: 1 1 100px;
}

%flex-layout {
    display: flex;
    justify-content: space-between;

    .box { @extend %box; }

    .box-s {
        @extend %box;

        flex-basis: 200px;
    }

    .box-m {
        @extend %box;

        flex-basis: 400px;
    }

    .box-l {
        @extend %box;

        flex-basis: 600px;
    }

    .box-half {
        @extend %box;

        flex-basis: 50%;
    }

    .box-max {
        @extend %box;

        flex-basis: 100%;
    }
}

.flex-layout {
    @extend %flex-layout;

    flex-direction: row;
    flex-wrap: wrap;

    &.row-nowrap {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &.col-wrap {
        flex-direction: column;
        flex-wrap: wrap;
    }

    &.col-nowrap {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    &.flex-align-start {
        align-items: flex-start;
    }

    &.flex-align-end {
        align-items: flex-end;
    }

    &.flex-align-center {
        align-items: center;
    }

    &.flex-align-stretch {
        align-items: stretch;
    }

    &.flex-align-baseline {
        align-items: baseline;
    }

    &.flex-justify-start {
        justify-content: flex-start;
    }

    &.flex-justify-center {
        justify-content: center;
    }

    &.flex-justify-end {
        justify-content: flex-end;
    }

    &.flex-justify-space-between {
        justify-content: space-between;
    }

    &.flex-justify-space-around {
        justify-content: space-around;
    }
}

.width-100 {
    width: 100%;
}
