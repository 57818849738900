.display-block {
    display: block;
}

.display-inline {
    display: inline;
}

.display-inline-block {
    display: inline-block;
}

.display-none {
    display: none;
}

.display-hidden {
    visibility: hidden;
}

.display-invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.opacity-0 {
    opacity: 0;
}

.opacity-quarter {
    opacity: 0.25;
}

.opacity-half {
    opacity: 0.5;
}

.opacity-3quarter {
    opacity: 0.75;
}

.opacity-full {
    opacity: 1;
}

.opacity-hover-full {
    &:hover {
        @extend .opacity-full;
    }
}
