.italic { font-style: italic; }

.caps {
    text-transform: uppercase;
}

.text-transform-none {
    text-transform: none;
}

.text-decoration-none {
    text-decoration: none;
}

.left-align { text-align: left; }
.center { text-align: center; }
.right-align { text-align: right; }
.justify { text-align: justify; }

.nowrap { white-space: nowrap; }
.break-word { word-wrap: break-word; }
.pre-wrap { white-space: pre-wrap; }

.line-height-1 { line-height: $line-height-01; }
.line-height-2 { line-height: $line-height-02; }
.line-height-3 { line-height: $line-height-03; }
.line-height-4 { line-height: $line-height-04; }
.line-height-5 { line-height: $line-height-05; }

.underline { text-decoration: underline; }

.code,
.mono {
    @extend %code;
}

%link,
.link {
    cursor: pointer;
    color: $color-brand;
    text-decoration: none;

    &:hover {
        color: $color-brand-washed;
    }
}

.list-style-disc {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: disc;
}

.list-style-circle {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: circle;
}

.list-style-square {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: square;
}

.list-style-decimal {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: decimal;
}

.list-style-none {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: none;
}

.list-style-inherit {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: inherit;
}

.list-style-initial {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: initial;
}

.list-style-unset {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: unset;
}

.list-style-lower-roman {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: lower-roman;
}

.list-style-upper-roman {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: upper-roman;
}

.list-style-lower-greek {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: lower-greek;
}

.list-style-decimal-leading-zero {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: decimal-leading-zero;
}

.list-style-upper-alpha {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: upper-alpha;
}

.list-style-lower-alpha {
    padding-left: $padding-default;
    margin-left: $margin-default;
    list-style-type: lower-alpha;
}
