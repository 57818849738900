$border-width-01: 1px;
$border-width-02: 3px;
$border-width-03: 5px;

$border-radius-0: 0;
$border-radius-1: 0.25rem;
$border-radius-2: 0.4rem;
$border-radius-3: 0.6rem;
$border-radius-circle: 100%;
$border-radius-pill: 9999px;

$border-color-default: $color-primary-02;
