/* brand */
$cisco-blue: #049fd9;
$cisco-blue-washed: #a3e2ff;
$cisco-blue-burned: #03688e;
$cisco-blue-highlight: #ebfaff;

/* neutrals or primaries */
$neutral-70: #39393b;
$neutral-60: #58585b;
$neutral-30: #9e9ea2;
$neutral-20: #c6c7ca;
$neutral-15: #dfdfdf;
$neutral-05: #f2f2f2;
$neutral-00: #fff;

/* statuses / alerts */
$info: #049fd9;
$info-washed: #a3e2ff;
$info-burned: #03688e;
$danger: #cf2030;
$danger-washed: #ffd9dc;
$danger-burned: #82000b;
$danger-highlight: #fae8ea;
$success: #6cc04a;
$success-washed: #b8e986;
$success-burned: #307316;
$warning: #fbab18;
$warning-washed: #ffec99;
$warning-burned: #d48a00;

/* category / secondary */
$sagegreen-burned-2: #637507;
$sagegreen-burned-1: #869c19;
$sagegreen: #abc233;
$sagegreen-washed: #eeff9c;

$turqoise-burned-2: #00594c;
$turqoise-burned-1: #03806d;
$turqoise: #14a792;
$turqoise-washed: #d1fff8;

$purple-burned-2: #501752;
$purple-burned-1: #752e78;
$purple: #9a4b9d;
$purple-washed: #ecc5ed;

$coral-burned-2: #b32c20;
$coral-burned-1: #d94a3d;
$coral: #ff6f61;
$coral-washed: #ffe8e5;

$yellow-burned-2: #d9b100;
$yellow-burned-1: #fc0;
$yellow: #ffde4c;
$yellow-washed: #ffec99;

$grape-burned-2: #1d356e;
$grape-burned-1: #355294;
$grape: #5674b9;
$grape-washed: #cfddff;

$teal-burned-2: #006875;
$teal-burned-1: #00899c;
$teal: #00acc1;
$teal-washed: #d9fbff;

$magenta-burned-2: #a10b3d;
$magenta-burned-1: #c72259;
$magenta: #ec407a;
$magenta-washed: #ffb2cc;

$darkcoolgrey-burned-2: #273347;
$darkcoolgrey-burned-1: #46556e;
$darkcoolgrey: #6a7890;
$darkcoolgrey-washed: #b6bac2;

/* THEME COLOR CODES - MAPS HEX TO CLASSNAMES */

/* brand */
$color-brand:           $cisco-blue;
$color-brand-washed:    $cisco-blue-washed;
$color-brand-burned:    $cisco-blue-burned;

/* LEGACY, refer to neutrals going forward */
$color-primary-01: $neutral-70;
$color-primary-02: $neutral-60;
$color-primary-03: $neutral-30;
$color-primary-04: $neutral-15;
$color-primary-05: $neutral-05;
$color-primary-06: $neutral-00;

/* neutrals */
$color-neutral-70: $neutral-70;
$color-neutral-60: $neutral-60;
$color-neutral-30: $neutral-30;
$color-neutral-20: $neutral-20;
$color-neutral-15: $neutral-15;
$color-neutral-05: $neutral-05;
$color-neutral-00: $neutral-00;

/* status */

$color-info:        $info;
$color-info-washed: $info-washed;
$color-info-burned: $info-burned;

$color-success:        $success;
$color-success-washed: $success-washed;
$color-success-burned: $success-burned;

$color-warning:        $warning;
$color-warning-washed: $warning-washed;
$color-warning-burned: $warning-burned;

$color-danger:        $danger;
$color-danger-washed: $danger-washed;
$color-danger-burned: $danger-burned;

/* categories */
$color-secondary-01:          $sagegreen;
$color-secondary-01-washed:   $sagegreen-washed;
$color-secondary-01-burned-1: $sagegreen-burned-1;
$color-secondary-01-burned-2: $sagegreen-burned-2;

$color-secondary-02:          $turqoise;
$color-secondary-02-washed:   $turqoise-washed;
$color-secondary-02-burned-1: $turqoise-burned-1;
$color-secondary-02-burned-2: $turqoise-burned-2;

$color-secondary-03:          $purple;
$color-secondary-03-washed:   $purple-washed;
$color-secondary-03-burned-1: $purple-burned-1;
$color-secondary-03-burned-2: $purple-burned-2;

$color-secondary-04:          $coral;
$color-secondary-04-washed:   $coral-washed;
$color-secondary-04-burned-1: $coral-burned-1;
$color-secondary-04-burned-2: $coral-burned-2;

$color-secondary-05:          $yellow;
$color-secondary-05-washed:   $yellow-washed;
$color-secondary-05-burned-1: $yellow-burned-1;
$color-secondary-05-burned-2: $yellow-burned-2;

$color-secondary-06:          $grape;
$color-secondary-06-washed:   $grape-washed;
$color-secondary-06-burned-1: $grape-burned-1;
$color-secondary-06-burned-2: $grape-burned-2;

$color-secondary-07:          $teal;
$color-secondary-07-washed:   $teal-washed;
$color-secondary-07-burned-1: $teal-burned-1;
$color-secondary-07-burned-2: $teal-burned-2;

$color-secondary-08:          $magenta;
$color-secondary-08-washed:   $magenta-washed;
$color-secondary-08-burned-1: $magenta-burned-1;
$color-secondary-08-burned-2: $magenta-burned-2;

$color-secondary-09:          $darkcoolgrey;
$color-secondary-09-washed:   $darkcoolgrey-washed;
$color-secondary-09-burned-1: $darkcoolgrey-burned-1;
$color-secondary-09-burned-2: $darkcoolgrey-burned-2;

/* highlights */
$color-highlight:        $cisco-blue-highlight;
$color-brand-highlight:  $cisco-blue-highlight;
$color-danger-highlight: $danger-highlight;
