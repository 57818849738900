.in-text {
    width: 100%;
    display: block;
    box-sizing: border-box;
}

.in-checkbox {
    width: 1.8em;
    bottom: 0.1em;
    position: relative;
    vertical-align: middle;
}
