@each $duration in $durations {
    .transition-#{index($durations, $duration)} {
        transition-duration: $duration;
    }
}

@each $easing, $timingFunc in $easings {
    .transition-#{$easing} {
        transition-timing-function: $timingFunc;
    }
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-spin {
    animation: spin 2s linear infinite;
}

@keyframes spin { 100% { transform: rotate(360deg); } }
