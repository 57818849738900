.margin { margin: $margin-default; }
.margin-auto { margin: auto; }
.margin-0 { margin: $spacing-0; }
.margin-1 { margin: $spacing-1; }
.margin-2 { margin: $spacing-2; }
.margin-3 { margin: $spacing-3; }
.margin-4 { margin: $spacing-4; }
.margin-5 { margin: $spacing-5; }
.margin-6 { margin: $spacing-7; }
.margin-7 { margin: $spacing-7; }

.margin-left { margin-left: $margin-default; }
.margin-left-auto { margin-left: auto; }
.margin-left-0 { margin-left: $spacing-0; }
.margin-left-1 { margin-left: $spacing-1; }
.margin-left-2 { margin-left: $spacing-2; }
.margin-left-3 { margin-left: $spacing-3; }
.margin-left-4 { margin-left: $spacing-4; }
.margin-left-5 { margin-left: $spacing-5; }
.margin-left-6 { margin-left: $spacing-6; }
.margin-left-7 { margin-left: $spacing-7; }

.margin-right { margin-right: $margin-default; }
.margin-right-auto { margin-right: auto; }
.margin-right-0 { margin-right: $spacing-0; }
.margin-right-1 { margin-right: $spacing-1; }
.margin-right-2 { margin-right: $spacing-2; }
.margin-right-3 { margin-right: $spacing-3; }
.margin-right-4 { margin-right: $spacing-4; }
.margin-right-5 { margin-right: $spacing-5; }
.margin-right-6 { margin-right: $spacing-6; }
.margin-right-7 { margin-right: $spacing-7; }

.margin-bottom { margin-bottom: $margin-default; }
.margin-bottom-auto { margin-bottom: auto; }
.margin-bottom-0 { margin-bottom: $spacing-0; }
.margin-bottom-1 { margin-bottom: $spacing-1; }
.margin-bottom-2 { margin-bottom: $spacing-2; }
.margin-bottom-3 { margin-bottom: $spacing-3; }
.margin-bottom-4 { margin-bottom: $spacing-4; }
.margin-bottom-5 { margin-bottom: $spacing-5; }
.margin-bottom-6 { margin-bottom: $spacing-6; }
.margin-bottom-7 { margin-bottom: $spacing-7; }

.margin-top { margin-top: $margin-default; }
.margin-top-auto { margin-top: auto; }
.margin-top-0 { margin-top: $spacing-0; }
.margin-top-1 { margin-top: $spacing-1; }
.margin-top-2 { margin-top: $spacing-2; }
.margin-top-3 { margin-top: $spacing-3; }
.margin-top-4 { margin-top: $spacing-4; }
.margin-top-5 { margin-top: $spacing-5; }
.margin-top-6 { margin-top: $spacing-6; }
.margin-top-7 { margin-top: $spacing-7; }

.margin-vertical {
    margin-top: $margin-default;
    margin-bottom: $margin-default;
}

.margin-vertical-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.margin-vertical-0 {
    margin-top: $spacing-0;
    margin-bottom: $spacing-0;
}

.margin-vertical-1 {
    margin-top: $spacing-1;
    margin-bottom: $spacing-1;
}

.margin-vertical-2 {
    margin-top: $spacing-2;
    margin-bottom: $spacing-2;
}

.margin-vertical-3 {
    margin-top: $spacing-3;
    margin-bottom: $spacing-3;
}

.margin-vertical-4 {
    margin-top: $spacing-4;
    margin-bottom: $spacing-4;
}

.margin-vertical-5 {
    margin-top: $spacing-5;
    margin-bottom: $spacing-5;
}

.margin-vertical-6 {
    margin-top: $spacing-6;
    margin-bottom: $spacing-6;
}

.margin-vertical-7 {
    margin-top: $spacing-7;
    margin-bottom: $spacing-7;
}

.margin-horizontal {
    margin-left: $margin-default;
    margin-right: $margin-default;
}

.margin-horizontal-auto {
    margin-left: auto;
    margin-right: auto;
}

.margin-horizontal-0 {
    margin-left: $spacing-0;
    margin-right: $spacing-0;
}

.margin-horizontal-1 {
    margin-left: $spacing-1;
    margin-right: $spacing-1;
}

.margin-horizontal-2 {
    margin-left: $spacing-2;
    margin-right: $spacing-2;
}

.margin-horizontal-3 {
    margin-left: $spacing-3;
    margin-right: $spacing-3;
}

.margin-horizontal-4 {
    margin-left: $spacing-4;
    margin-right: $spacing-4;
}

.margin-horizontal-5 {
    margin-left: $spacing-5;
    margin-right: $spacing-5;
}

.margin-horizontal-6 {
    margin-left: $spacing-6;
    margin-right: $spacing-6;
}

.margin-horizontal-7 {
    margin-left: $spacing-7;
    margin-right: $spacing-7;
}

// negative
.margin-negative { margin: $margin-negative-default; }
.margin-negative-1 { margin: $spacing-negative-1; }
.margin-negative-2 { margin: $spacing-negative-2; }
.margin-negative-3 { margin: $spacing-negative-3; }
.margin-negative-4 { margin: $spacing-negative-4; }
.margin-negative-5 { margin: $spacing-negative-5; }
.margin-negative-6 { margin: $spacing-negative-6; }
.margin-negative-7 { margin: $spacing-negative-7; }

.margin-negative-left { margin-left: $margin-negative-default; }
.margin-negative-left-1 { margin-left: $spacing-negative-1; }
.margin-negative-left-2 { margin-left: $spacing-negative-2; }
.margin-negative-left-3 { margin-left: $spacing-negative-3; }
.margin-negative-left-4 { margin-left: $spacing-negative-4; }
.margin-negative-left-5 { margin-left: $spacing-negative-5; }
.margin-negative-left-6 { margin-left: $spacing-negative-6; }
.margin-negative-left-7 { margin-left: $spacing-negative-7; }

.margin-negative-right { margin-right: $margin-negative-default; }
.margin-negative-right-1 { margin-right: $spacing-negative-1; }
.margin-negative-right-2 { margin-right: $spacing-negative-2; }
.margin-negative-right-3 { margin-right: $spacing-negative-3; }
.margin-negative-right-4 { margin-right: $spacing-negative-4; }
.margin-negative-right-5 { margin-right: $spacing-negative-5; }
.margin-negative-right-6 { margin-right: $spacing-negative-6; }
.margin-negative-right-7 { margin-right: $spacing-negative-7; }

.margin-negative-bottom { margin-bottom: $margin-negative-default; }
.margin-negative-bottom-1 { margin-bottom: $spacing-negative-1; }
.margin-negative-bottom-2 { margin-bottom: $spacing-negative-2; }
.margin-negative-bottom-3 { margin-bottom: $spacing-negative-3; }
.margin-negative-bottom-4 { margin-bottom: $spacing-negative-4; }
.margin-negative-bottom-5 { margin-bottom: $spacing-negative-5; }
.margin-negative-bottom-6 { margin-bottom: $spacing-negative-6; }
.margin-negative-bottom-7 { margin-bottom: $spacing-negative-7; }

.margin-negative-top { margin-top: $margin-negative-default; }
.margin-negative-top-1 { margin-top: $spacing-negative-1; }
.margin-negative-top-2 { margin-top: $spacing-negative-2; }
.margin-negative-top-3 { margin-top: $spacing-negative-3; }
.margin-negative-top-4 { margin-top: $spacing-negative-4; }
.margin-negative-top-5 { margin-top: $spacing-negative-5; }
.margin-negative-top-6 { margin-top: $spacing-negative-6; }
.margin-negative-top-7 { margin-top: $spacing-negative-7; }

.margin-negative-vertical {
    margin-top: $margin-negative-default;
    margin-bottom: $margin-negative-default;
}

.margin-negative-vertical-1 {
    margin-top: $spacing-negative-1;
    margin-bottom: $spacing-negative-1;
}

.margin-negative-vertical-2 {
    margin-top: $spacing-negative-2;
    margin-bottom: $spacing-negative-2;
}

.margin-negative-vertical-3 {
    margin-top: $spacing-negative-3;
    margin-bottom: $spacing-negative-3;
}

.margin-negative-vertical-4 {
    margin-top: $spacing-negative-4;
    margin-bottom: $spacing-negative-4;
}

.margin-negative-vertical-5 {
    margin-top: $spacing-negative-5;
    margin-bottom: $spacing-negative-5;
}

.margin-negative-vertical-6 {
    margin-top: $spacing-negative-6;
    margin-bottom: $spacing-negative-6;
}

.margin-negative-vertical-7 {
    margin-top: $spacing-negative-7;
    margin-bottom: $spacing-negative-7;
}

.margin-negative-horizontal {
    margin-left: $margin-negative-default;
    margin-right: $margin-negative-default;
}

.margin-negative-horizontal-1 {
    margin-left: $spacing-negative-1;
    margin-right: $spacing-negative-1;
}

.margin-negative-horizontal-2 {
    margin-left: $spacing-negative-2;
    margin-right: $spacing-negative-2;
}

.margin-negative-horizontal-3 {
    margin-left: $spacing-negative-3;
    margin-right: $spacing-negative-3;
}

.margin-negative-horizontal-4 {
    margin-left: $spacing-negative-4;
    margin-right: $spacing-negative-4;
}

.margin-negative-horizontal-5 {
    margin-left: $spacing-negative-5;
    margin-right: $spacing-negative-5;
}

.margin-negative-horizontal-6 {
    margin-left: $spacing-negative-6;
    margin-right: $spacing-negative-6;
}

.margin-negative-horizontal-7 {
    margin-left: $spacing-negative-7;
    margin-right: $spacing-negative-7;
}
